import React from 'react'

import { MyProfileMessages } from '@/domain/models/messages/messages'
import { CnCreditDataResponse } from '@/domain/use-cases/person/minnegocio/get-cn-credit-data'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { Typography } from '@naturacosmeticos/natds-web'
import { DefaultMinimumOrder } from './default-minimum-order'
import { MinimumOrderByCompany } from './minimum-order-by-company'

export const MinimumOrder = ({
  credit,
  cnCredit,
  componentClasses,
}: {
  cnCredit: CnCreditDataResponse
  componentClasses?: ClassNameMap<string>
  credit: MyProfileMessages['credit']
}) => {
  const hasMinimumOrderByCompany = cnCredit?.minimumOrderByCompany?.length > 0

  return (
    <div className={componentClasses?.content}>
      <Typography variant="body2" color="textSecondary">
        {credit.minimumOrder}
      </Typography>

      {hasMinimumOrderByCompany ? (
        <MinimumOrderByCompany
          minimumOrderByCompany={cnCredit.minimumOrderByCompany}
          classes={componentClasses}
        />
      ) : (
        <DefaultMinimumOrder minimumOrder={cnCredit.minimumOrder} classes={componentClasses} />
      )}
    </div>
  )
}
