import { getPageMessages } from '@/data/use-cases/messages/get-page-messages'
import { PossiblePageMessageOptions, PossiblePageMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { useCallback } from 'react'
import { useI18n } from './useI18n'

type GetPageMessagesWithNewOptions = (newOptions: PossiblePageMessageOptions) => PossiblePageMessages

export const usePageMessages = (page: Page, options?: PossiblePageMessageOptions): { messages: PossiblePageMessages, getPageMessagesWithNewOptions: GetPageMessagesWithNewOptions } => {
  const { translate } = useI18n()
  const getPageMessagesWithNewOptions = useCallback(
    (newOptions: PossiblePageMessageOptions) => getPageMessages(page, translate, newOptions), [page, translate],
  )

  return { messages: getPageMessages(page, translate, options), getPageMessagesWithNewOptions }
}
