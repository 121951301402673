import { CountryId } from '@/domain/models/country'

export const getGenderNames = (countryId: CountryId, key: string) => {
  switch (countryId) {
    case CountryId.Malaysia:
      return genders_en[key]
    case CountryId.Chile:
    case CountryId.Colombia:
      return genders_cl[key]
    case CountryId.Brazil:
      return genders_pt[key]
    case CountryId.Mexico:
      return genders_mx[key]
    default:
      return genders_es[key]
  }
}

const genders_en = {
  male: 'Male',
  female: 'Female',
  other: 'Other',
  transmale: 'Transgender Male',
  transfemale: 'Transgender Female',
  nonbinary: 'Non-Binary',
  notdeclared: 'Not Declared',
  queer: 'Queer',
}

const genders_cl = {
  male: 'Masculino',
  female: 'Femenino',
  other: 'Prefiero no informar',
  transmale: 'Hombre Transgénero',
  transfemale: 'Mujer Transgénero',
  nonbinary: 'No Binario',
  notdeclared: 'No Declarado',
  queer: 'Queer',
}

const genders_es = {
  male: 'Masculino',
  female: 'Femenino',
  other: 'Otro',
  transmale: 'Hombre Transgénero',
  transfemale: 'Mujer Transgénero',
  nonbinary: 'No Binario',
  notdeclared: 'No Declarado',
  queer: 'Queer',
}

const genders_pt = {
  male: 'Homem',
  female: 'Mulher',
  other: 'Uso outro termo',
  transmale: 'Homem Transgênero',
  transfemale: 'Mulher Transgênero',
  nonbinary: 'Não Binário',
  notdeclared: 'Prefiro não declarar',
  queer: 'Queer',
}

const genders_mx = {
  male: 'Hombre',
  female: 'Mujer',
  other: 'Otro',
  transmale: 'Hombre Transgénero',
  transfemale: 'Mujer Transgénero',
  nonbinary: 'No Binario',
  notdeclared: 'No Declarado',
  queer: 'Queer',
}
