import React from 'react'

import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { Typography } from '@naturacosmeticos/natds-web'

export const DefaultMinimumOrder = ({
  minimumOrder,
  classes,
}: {
  minimumOrder: number
  classes?: ClassNameMap<string>
}) => {
  return (
    <div className={classes?.textContent}>
      <Typography variant="subtitle1">{minimumOrder ?? '-'}</Typography>
    </div>
  )
}
