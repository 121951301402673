import { GridItem, Typography } from '@naturacosmeticos/natds-web'
import React, { useContext, useEffect, useState } from 'react'

import { CompanyId } from '@/domain/models/company/company-id'
import { MyProfileMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { PersonDataResponse } from '@/domain/use-cases/person'
import {
  CnCreditDataResponse,
  CnCreditDataResponseError,
} from '@/domain/use-cases/person/minnegocio/get-cn-credit-data'
import { Card } from '@/main/components'
import { IdentityInfoContext } from '@/main/contexts'
import { useMiGroupRedPage } from '@/main/hooks/useMiGroupRedPage'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { makeRegisterMaitenancePageApi } from '@/main/pages/register-maintenance/api/make-register-maintenance-page-api'
import { useContactStyles } from './cn-credit.styles'
import { MinimumOrder } from './minimum-order'

export type CnCreditDataProps = {
  personData: PersonDataResponse
  accessTokenNaturaWeb: string
}

export const CnCredit: React.FC<CnCreditDataProps> = ({ personData: { person }, accessTokenNaturaWeb }) => {
  const api = makeRegisterMaitenancePageApi()
  const [cnCredit, setCnCredit] = useState<CnCreditDataResponse>({})
  const { personId } = person
  const componentClasses = useContactStyles()
  const { companyId, countryId } = useContext(IdentityInfoContext)
  const { credit } = usePageMessages(Page.MyProfile).messages as MyProfileMessages
  const { isMiGroupRedPage, creditMiGrupoRedData } = useMiGroupRedPage()

  useEffect(() => {
    getCnCreditData()
  }, [person])

  const getCnCreditData = async () => {
    if (isMiGroupRedPage) {
      setCnCredit({
        minimumOrder: creditMiGrupoRedData.minimumOrder,
        available: creditMiGrupoRedData.available,
        limit: creditMiGrupoRedData.limit,
        minimumOrderByCompany: creditMiGrupoRedData?.minimumOrderByCompany
          ?.filter((company) => [CompanyId.Avon, CompanyId.Natura].includes(company.companyId))
          ?.sort((a, b) => a.companyId - b.companyId),
      })
      return
    }

    const data = (await api.getCnCredit({
      personId,
      countryId,
      companyId,
      authorization: accessTokenNaturaWeb,
    })) as CnCreditDataResponse | CnCreditDataResponseError

    if ('error' in data) {
      return
    }

    setCnCredit({
      minimumOrder: data.minimumOrder,
      available: data.available,
      limit: data.limit,
      additional: data.additional,
      minimumOrderByCompany: data?.minimumOrderByCompany
        ?.filter((company) => [CompanyId.Avon, CompanyId.Natura].includes(company.companyId))
        ?.sort((a, b) => a.companyId - b.companyId),
    })
  }

  return (
    <>
      <GridItem className="natds2" lg={4} md={4} sm={12} xs={12} data-testid="cn-credit">
        <Card data-testid="consultant-data" className={componentClasses.container}>
          <MinimumOrder cnCredit={cnCredit} componentClasses={componentClasses} credit={credit} />
        </Card>
      </GridItem>
      <GridItem className="natds2" lg={4} md={4} sm={12} xs={12}>
        <Card data-testid="consultant-data" className={componentClasses.container}>
          <div className={componentClasses.content}>
            <Typography variant="body2" color="textSecondary">
              {credit.availableCredit}
            </Typography>
            <div className={componentClasses.textContent}>
              <Typography variant="subtitle1">{cnCredit.available ?? '-'}</Typography>
            </div>
          </div>
        </Card>
      </GridItem>
      <GridItem className="natds2" lg={4} md={4} sm={12} xs={12}>
        <Card data-testid="consultant-data" className={componentClasses.container}>
          <div className={componentClasses.content}>
            <Typography variant="body2" color="textSecondary">
              {credit.totalCredit}
            </Typography>
            <div className={componentClasses.textContent}>
              <Typography variant="subtitle1">{cnCredit.limit || '-'}</Typography>
            </div>
          </div>
        </Card>
      </GridItem>
    </>
  )
}
