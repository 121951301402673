import React from 'react'

import { MinimumOrderByCompany as MinimumOrderByCompanyType } from '@/domain/use-cases/person/minnegocio/get-cn-credit-data'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { Typography } from '@naturacosmeticos/natds-web'

export const MinimumOrderByCompany = ({
  minimumOrderByCompany,
  classes,
}: {
  minimumOrderByCompany: MinimumOrderByCompanyType[]
  classes?: ClassNameMap<string>
}) => {
  return (
    <div className={classes?.minimumOrderWrapper}>
      {minimumOrderByCompany.map(({ company, minimumOrder }) => (
        <div key={company}>
          <Typography variant="body2" color="textSecondary" className="companyName">
            {company?.toLowerCase()}
          </Typography>
          <Typography variant="subtitle1">{minimumOrder ?? '-'}</Typography>
        </div>
      ))}
    </div>
  )
}
