import { AddressPageForms } from '@/data/use-cases/register-maintenance/remote-get-multiple-address'
import { PhoneType } from '@/domain/models/person'
import { AddressOrder, AddressPageType } from '../../address'
import { AgreementProduct } from '../../agreement/agreement-product'
import { AttachmentType } from '../../attachments/attachments'
import { companyIconName } from '../../company/company-icon-name'
import { CompanyName } from '../../company/company-name'
import { DigitalStoreTermsEnum } from '../../digital-store/digital-store-terms-type'
import { Document } from '../../documents/documents'
import { VendorId } from '../../vendor/vendor-id'
import { shouldRenderRecommenderField } from './configs.AVON_MEX'
import {
  AddressConfigs,
  AttachmentsConfigs,
  AuthorizationCodeConfigs,
  CompletedConfigs,
  DigitalStoreConfigs,
  DocumentationConfigs,
  MaintenancePersonalDataConfigs,
  MaintenanceRegisterConfigs,
  PagesTenantConfigs,
  PersonalDataConfigs,
  PreRegisterConfigs,
  ProfileConfigs,
} from './configs.d'

const personalData: PersonalDataConfigs = {
  recommender: {
    shouldDisplay: ({ authenticated }) => shouldRenderRecommenderField(authenticated),
  },
  isDeliveryAddress: true,
  shouldUsePhoneSelect: false,
  addressWithExternalCode: true,
  showAsteriskInRequiredLabel: false,
  country: 'ECUADOR',
  shouldUseSeparatedNames: true,
  namesOptions: ['firstName', 'middleName', 'lastName'],
  allowedCharsRegex: new RegExp(/^[a-zA-Z ]+$/),
  allocationStrategy: { levels: { length: 3 } },
}

const preRegister: PreRegisterConfigs = {
  documents: [
    {
      type: Document.CedulaDeIdentidad,
      name: 'Cedula de identidad',
      validChars: /^[\w˜Ñ]{0,10}$/m,
      rules: {
        pattern: /[\d]*/,
        minLength: 10,
        maxLength: 10,
      },
    },
  ],
  phones: [
    {
      type: PhoneType.CELLULAR,
      mask: [
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        ' ',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        ' ',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
      ],
      length: 10,
    },
  ],
  shouldAcceptTerms: true,
}

const attachments: AttachmentsConfigs = {
  documents: [
    {
      type: Document.CedulaDeIdentidad,
      name: 'Cedula de identidade',
      attachments: [{ title: '', type: AttachmentType.CedulaIdentidadFront }],
    },
  ],
  canChooseDocument: false,
}

const digitalStore: DigitalStoreConfigs = {
  showSkipButton: false,
  showEmailInfoRegistration: false,
  acceptTermsStrategy: DigitalStoreTermsEnum.DEFAULT,
  vendorId: VendorId.EMPTY,
  agreement: {
    products: [AgreementProduct.DIGITAL_STORE],
  },
  digitalStoreNameMaxOfChars: null,
  shouldUnlinkAgreements: true,
}

const address: AddressConfigs = {
  pageType: AddressPageType.SimplePage,
  shouldDisableTheFilledInput: false,
  geographicalLevels: { length: 3 },
  complement: {
    rules: {
      maxLength: 50,
      pattern: /^[^ç]*$/,
    },
  },
  number: {
    validChars: null,
    hasNoNumberCheckbox: true,
    rules: {
      maxLength: 6,
      pattern: /^[^ç]*$/,
    },
  },
  street: {
    maxLength: 140,
    rules: {
      pattern: /^[^ç]*$/,
    },
  },
  reference: {
    maxLength: 80,
    rules: {
      pattern: /^[^ç]*$/,
    },
  },
  showDeliveryCheckbox: true,
  fieldsOrder: [
    AddressOrder.CITY,
    AddressOrder.STATE,
    AddressOrder.NEIGHBORHOOD,
    AddressOrder.NUMBER,
    AddressOrder.COMPLEMENT,
    AddressOrder.REFERENCES,
  ],
  showAlertOnApprovedPage: false,
}

const documentation: DocumentationConfigs = {
  shouldAcceptTermsWhenAuthenticated: true,
  agreementProducts: [AgreementProduct.NATURA],
  shouldDisplayEstablishmentInfo: true,
}

const completed: CompletedConfigs = {
  rejected: {
    hasEmailContact: false,
  },
  shouldSearchIndication: false,
  shouldCheckStatus: true,
  shouldOnlyShowConfirmation: false,
  shouldShowIndicativePerson: true,
  shouldShowContactInfos: false,
  shouldShowConfirmationDescription: true,
  goToClickButton: {
    show: true,
    path: `${process.env.LANDING_PAGE_URL}`,
  },
  companyIconName: companyIconName[CompanyName.Natura],
}

const authorizationCode: AuthorizationCodeConfigs = {
  phoneNumberHyphenIndex: 3,
}

const maintenanceRegisterPage: MaintenanceRegisterConfigs = {
  phoneNumberAreaCode: '593',
  phoneNumberOptions: {
    mask: ['0', /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
    maxLength: 10,
    minLength: 10,
  },
  phoneNumberSecondaryOptions: {
    mask: [
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      ' ',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      ' ',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
    ],
    maxLength: 10,
    minLength: 10,
  },
  shouldEditEmail: true,
  shouldEditPhone: true,
  showPhoneNumberSecondary: true,
  primaryPhoneOptions: [1, 11],
  tenantTokenSecurityToggle: false,
  telephonesOptions: [
    {
      type: 1,
      mask: [/[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
      validation: /^([0-9]{10})$/,
      minLength: 10,
      maxLength: 10,
    },
    {
      type: 2,
      mask: [/[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
      validation: /^([0-9]{8})$/,
      minLength: 8,
      maxLength: 8,
    },
    {
      type: 5,
      mask: [/[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
      validation: /^([0-9]{8})$/,
      minLength: 8,
      maxLength: 8,
    },
    {
      type: 11,
      mask: [/[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
      validation: /^([0-9]{10})$/,
      minLength: 10,
      maxLength: 10,
    },
  ]
}

const maintenancePersonalDataPage: MaintenancePersonalDataConfigs = null

const profile: ProfileConfigs = {
  contact: {
    phoneMask: {
      maskRegex: /^(\d{3})(\d{3})(\d{4}).*/,
      mask: '$1 $2 $3',
    },
    editUrl: '/mfe-register/maintenanceRegisterPage/',
    minegocioEditUrl: '/mfe-register/maintenanceRegisterPage/',
  },
  consultantData: {
    editUrl: '/mfe-register/maintenancePersonalDataPage/',
    minegocioEditUrl: '/mfe-register/maintenancePersonalDataPage',
  },
  digitalSpace: {
    digitalSpaceBaseUrl: 'https://www.natura.com.br/consultoria/',
    mySpaceDashboard:
      'https://cadastro-experiencia-digital-store-fe.qa.naturacloud.com/?country=ec&company=natura',
    openDigitalSpaceUrl:
      'https://cadastro-experiencia-digital-store-fe.qa.naturacloud.com/?country=ec&company=natura',
    showDigitalSpace: false,
  },
  address: {
    getAddress: ({ street, number, complement, level1, level2, level3, level4 }: AddressPageForms) => {
      const levelFour = typeof level4 === 'object' ? level4.label : level4

      const address = [level1.label, level2.label, level3.label, levelFour, street, number, complement]
        .filter(Boolean)
        .join(', ')

      return address
    },
    getReference: ({ references }) => {
      return references || ''
    },
    editUrl: '/mfe-register/maintenanceAddressPage/',
    minegocioEditUrl: '/mfe-register/maintenanceAddressPage',
  },
  hasEditProfile: false,
  hasSelfDeclaration: false,
  hasGender: false,
  hasSocialName: false,
  showPhoneNumberSecondary: true,
  showGroupSectorLeader: true,
  showGrowthPlan: true,
  mgmBanner: {
    show: false,
    showToConsultant: false,
    showToLeader: false,
    showToManager: false,
  },
  showMyLeader: true,
  showCnCredit: true,
}

export const naturaEcuConfigs: PagesTenantConfigs = {
  documentation,
  personalData,
  attachments,
  preRegister,
  digitalStore,
  address,
  digitalSignaturePage: null,
  completed,
  authorizationCode,
  maintenanceRegisterPage,
  maintenancePersonalDataPage,
  profile,
}
