import React from 'react'
import { useFormContext } from 'react-hook-form'
import { ControlledTextField } from '@/main/components'

type NicknameProps = {
  messages: any,
  id: string,
}

export const NicknameField = ({
  messages,
  id,
}: NicknameProps) => {
  const { control } = useFormContext()

  const {
    label,
    placeholder,
    infoText,
  } = messages

  return (
    <ControlledTextField
      id={id}
      label={label}
      control={control}
      placeholder={placeholder}
      infoText={infoText}
      validChars={new RegExp(/^[A-Za-z0-9. ]*$/)}
    />
  )
}
